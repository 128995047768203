import React, { useCallback, useEffect, useMemo, useState } from 'react'
import OrderPage from '../../OrderPage'
import Window from '../../../utils/Window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CouponObj from '../../../features/coupons/couponObj';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, selectCartCoupon, selectCartItems, setCartCoupon, setCartUpdateInProgress, setOrderInProgress } from '../../../features/cart/cartSlice';
import OrderObj from '../../../features/orders/orderObj';
import { resetCustomer, selectCustomer } from '../../../features/customer/customerSlice';
import CustomerObj from '../../../features/customer/customerObj';
import CartObj from '../../../features/cart/cartObj';
import { selectProducts } from '../../../features/products/productsSlice';
import ShippingMethodObj from '../../../features/shipping/shippingMethodObj';
import { selectShippingMethods } from '../../../features/shipping/shippingSlice';
import { selectGeneralOptions, selectIsMobileRoute, selectLifeTimeAffiliate, selectShowProductFilters, selectVisitorID, setShowProductFilters } from '../../../features/mobile/mobileSlice';
import { selectToken } from '../../../features/user/userSlice';
import CheckoutUtils from '../../../features/checkout/checkoutUtils';
import OrdersAPI from '../../../API/ordersAPI';
import ProductObj from '../../../features/products/productObj';
import TiktokPixel from 'tiktok-pixel';
import { selectPendingOrder, setPendingOrder } from '../../../features/checkout/checkoutSlice';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { selectPointsCoupon, setPointsCoupon } from '../../../features/coupons/couponsSlice';
import { resetReferrals, selectAffiliate, selectVisit } from '../../../features/referrals/referralsSlice';
import { addOrder, updateOrder } from '../../../features/orders/ordersSlice';
import { mightyPointsToCurrency } from '../../../features/customer/mightyPointsUtils';
import { createCoupon } from '../../../API/coupons';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { selectEditCouponLines, selectExistingCartItems, selectSelectedDate, setExistingCartItems } from '../core/autoShipSlice';
import ProductFilterTags from '../../../features/products/filters/ProductFilterTags';
import SortFilter from '../../../features/products/filters/SortFilter';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export default function AutoshipMenuPage() {
    const params = useParams();
    const token = useSelector(selectToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedDate = useSelector(selectSelectedDate);
    const [errorMsg, setErrorMsg] = useState('');
    const custData = useSelector(selectCustomer);
    const pendingOrderData = useSelector(selectPendingOrder);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cart = new CartObj(useSelector(selectCartItems));
    const products = useSelector(selectProducts);
    const showProductFilters = useSelector(selectShowProductFilters);
    const affiliateData = useSelector(selectAffiliate);
    const referralVisitData = useSelector(selectVisit);
    const affiliateVisitorID = useSelector(selectVisitorID);
    const shippingMethods = useSelector(selectShippingMethods);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const lifeTimeAffiliate = useSelector(selectLifeTimeAffiliate);
    const pointsCoupon = useSelector(selectPointsCoupon);
    const ExistingCartItems = useSelector(selectExistingCartItems);
    const EditCouponLines = useSelector(selectEditCouponLines);
    const paymentOption = getGeneralOptions?.payment_option;
    const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
    const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};
    const [isUpsAvail, setIsUpsAvail] = useState(false);
    let isShipping: ShippingMethodObj[] = [];
    const [regionalShipLimit, seteRgionalShipLimit] = useState(0);
    const AppURL = useSelector(selectIsMobileRoute);
    const updateOrderId = params.orderId;
    const updateShippingId = location?.state && location?.state?.shippingId;
    const isEditableOrder = location.state.isEditOrder === true && location.state.pickedForMyself === false;
    const isNeedToChange = location.state.changeToSelect === true;
    const pointsBalance = customer.getMightyPoints();
    const maxUsablePoints = customer.getMaxUsablePoints(
        cart.getSubtotal(null, null, null) as number
    );
    const autoShipOrderDate = location.state && location.state.date ? location.state.date : null;

    useEffect(() => {
        const shippingCode = getMatchedMethods();
        const isUPSOrUPSFreeShipping = shippingCode[0]?.data.ups_delivery_method === true;
        if (isUPSOrUPSFreeShipping && getGeneralOptions) {
            seteRgionalShipLimit(Number(getGeneralOptions.regional_shipping_meal_limit));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGeneralOptions]);

    const addCouponToOrder = (order: OrderObj, shippingCost: number, newCoupon: any) => {
        if (!newCoupon) return;

        if (newCoupon.isPointsCoupon(customer)) {
            order.addPointsToMeta(
                maxUsablePoints,
                customer.data.mightypoints_discount_code
            );
        } else if (newCoupon.data.discount_type === 'smart_coupon') {
            order.addSmartCouponToMeta(
                newCoupon,
                cart.getTotal(products, shippingCost, null, customer) as number);
        }
        if (newCoupon.data && newCoupon.data.code) {
            order.data.coupon_lines = [{ "code": newCoupon.data.code }];
        }
    }

    const generateBrowserId = (): number => {
        const userAgent = navigator.userAgent;
        const language = navigator.language || (navigator as any).userLanguage || '';

        const fingerprint = `${userAgent} ${language}`;
        const hashedBrowserId = hashString(fingerprint);

        return hashedBrowserId;
    };

    const hashString = (data: string): number => {
        let hash = 0;

        if (data.length === 0) {
            return hash;
        }

        for (let i = 0; i < data.length; i++) {
            const char = data.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }

        return hash;
    };

    const getParameterByName = (name: string, url?: string): string | null => {
        if (!url) {
            url = window.location.href;
        }

        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    const getMatchedMethods = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isShipping = [];
        const zip = shipping_info.postcode ? shipping_info.postcode : billing_info.postcode;

        let matchedMethods = [];

        let hasFreeDelivery = false;

        for (const method of shippingMethods) {
            const sm = new ShippingMethodObj(method);
            if (sm.isMatch(zip, cart, products, cartCoupon, customer)) {

                if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
                    hasFreeDelivery = false;
                    matchedMethods.push(sm);
                } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
                    if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
                        hasFreeDelivery = true;
                        matchedMethods.push(sm);
                    } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
                        matchedMethods.push(sm);
                        if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
                            isShipping.push(sm);
                        }
                    }
                }

            }
        }

        if (hasFreeDelivery) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
        }


        return matchedMethods;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billing_info.postcode, shipping_info.postcode, shippingMethods, cart, products, cartCoupon, customer]);

    const createOrderFromFormValues = (customerId: number, newCoupon: any) => {
        const shippingCode = getMatchedMethods();
        let deliveryDate = undefined;

        if (!shippingCode[0].data.ID) return null;

        const shippingMethod = ShippingMethodObj.getById(
            shippingMethods,
            shippingCode[0].data.ID
        );

        if (!shippingMethod) return null;

        if (!shippingMethod.isEmailDelivery() && !(
            cart.hasProductWithCategory('gift-card', products) ||
            cart.hasProductWithCategory('mighty-bucks-gift-card', products)
        )) {
            if (!selectedDate) return null;

            deliveryDate = selectedDate.getFullYear().toString() + '-' +
                ('0' + (selectedDate.getMonth() + 1)).slice(-2) + '-' +
                ('0' + selectedDate.getDate()).slice(-2);
        }

        let data = {
            "customer_id": customerId,
            "billing": {
                "first_name": billing_info.first_name,
                "last_name": billing_info.last_name,
                "address_1": billing_info.address_1,
                "address_2": billing_info.address_2,
                "city": billing_info.city,
                "country": "US",
                "state": billing_info.state,
                "postcode": billing_info.postcode,
                "email": billing_info.email,
                "phone": billing_info.phone
            },
            "shipping": {},
            "line_items": cart.getLineItems(products),
            "coupon_lines": [],
            "shipping_lines": [{
                "method_id": 'flat_rate',
                "method_title": shippingMethod.data.title,
                "total": shippingMethod.data.cost
            }],
            "customer_note": "Is Autoship Order",
            "payment_method": creditCardProcessor,
            "payment_method_title": OrderObj.getPaymentMethodTitle(creditCardProcessor),
            "meta_data": [{
                "key": "Chosen Pickup Location",
                "value": shippingMethod.data.title === 'UPS Shipping' ? 'UPS' : "I'm having my meals delivered"
            }, {
                'key': '_mm_rest_order',
                'value': "true"
            }, {
                'key': '_autoship_order_type',
                'value': "pick_for_myself"
            }],
        }

        if (deliveryDate) {
            data.meta_data.push({
                "key": "_delivery_date",
                "value": deliveryDate
            });
        }

        if (!customer.hasMembership()) {
            data.meta_data.push({
                "key": "_wc_points",
                "value": cart.getPoints(products).toString()
            });
        }

        data.shipping = {
            "first_name": shipping_info.first_name ? shipping_info.first_name : billing_info.first_name,
            "last_name": shipping_info.last_name ? shipping_info.last_name : billing_info.last_name,
            "address_1": shipping_info.address_1 ? shipping_info.address_1 : billing_info.address_1,
            "address_2": shipping_info.address_2 ? shipping_info.address_2 : billing_info.address_2,
            "city": shipping_info.city ? shipping_info.city : billing_info.city,
            "country": "US",
            "state": shipping_info.state ? shipping_info.state : billing_info.state,
            "postcode": shipping_info.postcode ? shipping_info.postcode : billing_info.postcode,
        }

        let order = new OrderObj(data);

        if (newCoupon) {
            addCouponToOrder(order, parseFloat(shippingMethod.data.cost), newCoupon);
        }

        return order;
    }

    const submitAutoshipOrder = () => {
        if (isEditableOrder) {
            UpdateEditOrder();
        } else {
            let checkoutCtxt: Record<string, any> = {
                order: new OrderObj({}),
                token: token,
                customerId: 'id' in customer.data ? customer.data.id : null,
                authNonce: '',
                affiliateData: affiliateData,
                referralVisitData: referralVisitData
            }

            let newCoupon: any = null;

            setErrorMsg('');
            dispatch(setOrderInProgress(true));

            const handleMightyPointsAndCoupon = () => {
                return new Promise<void>(async (resolve, reject) => {
                    if (regionalShipLimit > 0 && regionalShipLimit > cart.getItemCount()) {
                        setIsUpsAvail(true);
                        reject('Regional shipping limit exceeded');
                        return;
                    }

                    if (pointsCoupon) {
                        if (pointsCoupon.amount === mightyPointsToCurrency(maxUsablePoints).toString()) {
                            dispatch(setCartCoupon({ token: '', coupon: pointsCoupon }));
                            newCoupon = { token: '', coupon: pointsCoupon };
                            dispatch(setCartUpdateInProgress(false));
                            resolve();
                            return;
                        } else {
                            dispatch(setPointsCoupon(null));
                            dispatch(setCartUpdateInProgress(false));
                        }
                    }

                    if (!('mightypoints_discount_code' in customer.data) ||
                        !customer.data.mightypoints_discount_code ||
                        !customer.data.email) {
                        dispatch(setCartUpdateInProgress(false));
                        reject('Mighty points discount code or email missing');
                        return;
                    }

                    dispatch(setCartUpdateInProgress(true));
                    const coupon_data = {
                        "code": customer.data.mightypoints_discount_code,
                        "amount": mightyPointsToCurrency(maxUsablePoints).toString(),
                        "usage_limit": 1,
                        "usage_limit_per_user": 1,
                        "enable_free_shipping": false,
                        "email_restrictions": [customer.data.email]
                    };

                    try {
                        const coupon = await createCoupon(token, coupon_data);
                        if (coupon && coupon.id) {
                            dispatch(setPointsCoupon(coupon));
                            dispatch(setCartCoupon({ token: '', coupon: coupon }));
                            newCoupon = { token: '', coupon: coupon };
                            dispatch(setCartUpdateInProgress(false));
                            resolve();
                        } else {
                            dispatch(setCartUpdateInProgress(false));
                            reject('Failed to create coupon');
                        }
                    } catch (error) {
                        dispatch(setCartUpdateInProgress(false));
                        reject(error);
                    }
                });
            };

            Promise.resolve().then(async () => {
                if (token && !cartCoupon && pointsBalance > 0 && !customer.hasMembership()) {
                    await handleMightyPointsAndCoupon();
                    newCoupon = await CouponObj.createCoupon(newCoupon.coupon);
                }

                const res = await OrdersAPI.checkOrderQTY(checkoutCtxt.token, [Object.keys(cart.items)]);

                const outOfStockProducts = res.data.filter((item: { message: string; stock: null; }) => item.message === "Out of stock" && item.stock !== null);

                if (outOfStockProducts.length > 0) {
                    const outOfStockProductNames = outOfStockProducts.map((product: { product_id: any; }) => {
                        const productsById = ProductObj.getById(products, Number(product.product_id));
                        return productsById?.data.name ? productsById?.data.name : `Unknown Product`;
                    }).join(', ');

                    setErrorMsg(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
                    throw new Error(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
                }

                let order = createOrderFromFormValues(checkoutCtxt.customerId, newCoupon);

                if (!order) {
                    setErrorMsg("An error has occurred while creating an order. Please try again later");
                    throw new Error("An error has occurred. Please try again later")
                };

                TiktokPixel.track(
                    'PlaceAnOrder',
                    {
                        content_id: cart.getProductIDs(),
                        content_name: cart.getProductNames(products),
                        quantity: cart.getItemCount(),
                        content_type: 'product',
                        value: checkoutCtxt.order.data.total,
                        currency: 'USD'
                    }
                );

                if (Object.keys(pendingOrderData).length > 0) {
                    const shippingCode = getMatchedMethods();
                    // Make deep copy of pending order
                    let updatedOrder = new OrderObj(JSON.parse(JSON.stringify(pendingOrderData)));
                    if (updatedOrder.ageInSeconds() < 3600) {
                        updatedOrder.updateData(order.data);
                        if (newCoupon && shippingCode[0].data.ID) {
                            const shippingMethod = ShippingMethodObj.getById(
                                shippingMethods,
                                shippingCode[0].data.ID
                            );

                            if (shippingMethod) {
                                addCouponToOrder(updatedOrder, parseFloat(shippingMethod.data.cost), newCoupon);
                            }
                        } else {
                            updatedOrder.removePointsFromMeta();
                            updatedOrder.removeSmartCouponFromMeta();
                        }

                        return OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id, updatedOrder.data);
                    } else {
                        // Pending order is over an hour old. Mark it canceled
                        try {
                            OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id,
                                { status: 'cancelled' });
                        } catch (e) {
                            setErrorMsg(`Error while canceling order.`)
                            OrdersAPI.createOrderNote(checkoutCtxt.token, pendingOrderData.id, `Error while canceling order (Pending order is over an hour old): ${e}`);
                        }
                        dispatch(setPendingOrder({}));
                    }
                }
                console.log("before create", order.data);
                return OrdersAPI.createOrder(checkoutCtxt.token, order.data);
            }).then((orderData) => {
                console.log("after create", orderData);
                if ('code' in orderData && 'message' in orderData) {
                    let errorMessage = orderData.message;

                    const isHtml = /<\/?[a-z][\s\S]*>/i.test(errorMessage);

                    if (isHtml) {
                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = errorMessage;
                        errorMessage = tempDiv.textContent || tempDiv.innerText;
                    }
                    setErrorMsg(errorMessage)
                    if (orderData.id) {
                        OrdersAPI.createOrderNote(checkoutCtxt.token, orderData.id, orderData.message);
                    }
                    throw new Error(errorMessage);
                }
                if (!('id' in orderData)) {
                    setErrorMsg('An error has occurred while creating an order id. Please try again later.')
                    throw new Error('An error has occurred. Please try again later.');
                }

                dispatch(setPendingOrder(orderData));
                // Deep copy order and add to context
                checkoutCtxt.order = new OrderObj(JSON.parse(JSON.stringify(orderData)));

            }).then(async () => {
                const browserId = generateBrowserId();
                const userClickID = getParameterByName('fbclid');
                let ipAddress = '';
                try {
                    const res = await axios.get("https://api.ipify.org/?format=json");
                    ipAddress = res.data.ip;
                } catch (error) {
                    console.error("Error fetching IP address from api.ipify", error);
                }

                // Order is complete. Track purchase with FB Pixel and GA4
                ReactPixel.track(
                    'Purchase',
                    {
                        currency: 'USD',
                        num_items: cart.getItemCount(),
                        content_type: 'product',
                        content_ids: cart.getProductIDs(),
                        content_name: cart.getProductNames(products),
                        value: checkoutCtxt.order.data.total,
                        click_id: userClickID,
                        browser_id: browserId,
                        ip_address: ipAddress,
                    }
                );
                ReactGA.event(
                    'purchase',
                    {
                        currency: 'USD',
                        transaction_id: checkoutCtxt.order.data.id,
                        value: checkoutCtxt.order.data.total,
                        items: cart.getItemsForGA(products)
                    }
                );
                TiktokPixel.track(
                    'CompletePayment',
                    {
                        content_id: cart.getProductIDs(),
                        content_name: cart.getProductNames(products),
                        quantity: cart.getItemCount(),
                        content_type: 'product',
                        value: checkoutCtxt.order.data.total,
                        currency: 'USD'
                    }
                );

                // Reddit Pixel tracking
                const email = customer.data.email ?
                    customer.data.email : checkoutCtxt.order.data.billing.email ?
                        checkoutCtxt.order.data.billing.email : '';
                const items = await Object.values(cart.items).map((item: any) => {
                    const product = ProductObj.getById(products, item.product_id);
                    return {
                        id: product?.data.id,
                        name: product?.data.name,
                        category: product?.data.categories,
                    }
                });
                (window as any).rdt('track', 'Purchase', {
                    email: email,
                    currency: 'USD',
                    itemCount: cart.getItemCount(),
                    transactionId: checkoutCtxt.order.data.id,
                    value: checkoutCtxt.order.data.total,
                    products: items
                });

                CheckoutUtils.maybePostReferral(customer, checkoutCtxt, lifeTimeAffiliate, affiliateVisitorID);
                dispatch(setPendingOrder({}));
                dispatch(clearCart(checkoutCtxt.token));
                dispatch(resetCustomer());
                dispatch(setPointsCoupon(null));
                dispatch(resetReferrals());

                const orderStatus = 'autoship';
                let orderData: Record<string, any> = { status: orderStatus };
                if ('authNetTransId' in checkoutCtxt) {
                    // Make deep copy of the meta_data
                    orderData.meta_data = JSON.parse(JSON.stringify(checkoutCtxt.order.data.meta_data));
                    orderData.meta_data.push({
                        "key": "_authorize_net_trans_id",
                        "value": checkoutCtxt.authNetTransId
                    });
                }
                return OrdersAPI.updateOrder(checkoutCtxt.token, checkoutCtxt.order.data.id, orderData);
            }).then((orderData) => {
                // Add the new order to orders slice
                if (orderData) {
                    checkoutCtxt.order = new OrderObj(orderData);
                    dispatch(addOrder(orderData));
                }
                navigate(`/autoship${AppURL}`);
            }).catch((e) => {
                setErrorMsg(e.message);
            }).finally(() => {
                dispatch(setOrderInProgress(false));
            });
        }
    }

    const formatDate = (date: any) => {
        if (!date) return '';
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const UpdateEditOrder = async () => {
        try {
            const shippingCode = getMatchedMethods();
            const shippingMethod = ShippingMethodObj.getById(
                shippingMethods,
                shippingCode[0].data.ID
            );

            dispatch(setOrderInProgress(true));
            const transformedExistingCartItems = Object.keys(ExistingCartItems).map(key => ({
                ...ExistingCartItems[key],
                product_qty: 0,
                quantity: 0
            }));

            const lineItems = [
                ...transformedExistingCartItems,
                ...cart.getLineItems(products)
            ];

            const orderData: Record<string, any> = {
                line_items: lineItems,
            };

            OrdersAPI.createOrderNote(token, Number(updateOrderId), "Customer changed cart items.");
            if (cartCoupon && cartCoupon.data.code) {
                orderData.coupon_lines = [{ "code": cartCoupon.data.code }];
            } else if (EditCouponLines.length > 0) {
                orderData.coupon_lines = [{ "code": EditCouponLines[0].code }];
            }

            if (shippingMethod) {
                orderData.shipping_lines = [{
                    "id": updateShippingId,
                    "method_id": 'flat_rate',
                    "method_title": shippingMethod.data.title,
                    "total": shippingMethod.data.cost
                }];
            }

            if (isNeedToChange) {
                OrdersAPI.createOrderNote(token, Number(updateOrderId), "Customer switched from Chef's choice to Pick For Myself.");
                orderData.meta_data = [{
                    'key': '_autoship_order_type',
                    'value': "pick_for_myself"
                }];
            }

            const response = await OrdersAPI.updateOrder(token, Number(updateOrderId), orderData);
            if ('id' in response) {
                dispatch(updateOrder(response));
                dispatch(clearCart(token));
                dispatch(setExistingCartItems({}));
                dispatch(setPendingOrder({}));
                dispatch(setPointsCoupon(null));
                dispatch(resetReferrals());
                navigate(isNeedToChange ? '/autoship' : `/autoship/orders/${updateOrderId}${AppURL}`);
            }

        } catch (e) {
            console.error("Error While Updating Order: ", e);
        } finally {
            dispatch(setOrderInProgress(false));
        }
    };

    return (
        <div>
            {isUpsAvail &&
                <Modal show={true}>
                    <Modal.Body className='font-barlow mb-4'>
                        <div className='text-center fs-4'>
                            <p className='mt-2'>Shipping requires a minimum of {regionalShipLimit} meals.
                                Please add {regionalShipLimit} meals to your cart to continue to checkout</p>
                        </div>
                        <div className='d-flex justify-content-center p-3'>
                            <Button variant="dark" onClick={() => setIsUpsAvail(false)}>Add Items To Cart</Button>
                        </div>
                    </Modal.Body>
                </Modal>}
            {Window.isMobile() &&
                <div className='editing-wrap'>
                    <div className='inner-wrap'>
                        <div className='text-editing'>Editing <span>{formatDate(new Date(autoShipOrderDate))}</span></div>
                        <FontAwesomeIcon
                            color='black'
                            className='cursor-pointer'
                            onClick={() => navigate('/autoship')}
                            icon={faClose}
                        ></FontAwesomeIcon>
                    </div>
                </div>}

            <div className='d-md-none px-4 pb-2 pt-1'>
                <div className='pb-2'>
                    <ProductFilterTags />
                </div>
                <Row className='product-btn-part'>
                    <Col xs={6}>
                        <Button
                            variant='dark'
                            className='font-glegoo fs-16px w-100'
                            disabled={showProductFilters}
                            onClick={() => dispatch(setShowProductFilters(true))}
                        >
                            Filter By
                            <FontAwesomeIcon
                                className='ms-2'
                                icon={faFilter}
                            ></FontAwesomeIcon>
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <SortFilter />
                    </Col>
                </Row>
            </div>
            <OrderPage submitAutoshipOrder={submitAutoshipOrder} errorMsg={errorMsg} />
        </div >
    )
}
