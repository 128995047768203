import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../AutoshipPages/core/autoShipPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { format, addMonths } from 'date-fns';
import Window from "../../utils/Window";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { selectCartCoupon, selectCartItems } from '../../features/cart/cartSlice';
import CartObj from '../../features/cart/cartObj';
import { selectCustomer, selectCustomerIsLoading } from '../../features/customer/customerSlice';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import DeliveryObj from '../../features/delivery/deliveryObj';
import { selectDeliveryData } from '../../features/delivery/deliverySlice';
import { selectProducts } from '../../features/products/productsSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoShipAPI from './core/autoshipAPI';
import { loadOrders, resetOrders, selectOrders, selectOrdersLoadComplete, selectUpdatingOrder, setUpdatingOrder, updateOrderDeliveryDate } from '../../features/orders/ordersSlice';
import OrderObj from '../../features/orders/orderObj';
import {
    selectAutoShipData,
    selectCalendarIndex,
    selectIsBulkOrder,
    selectNotifications,
    selectPreviousDate,
    selectSelectedDate,
    selectSelectedDates,
    selectSelectedZipCode,
    setCalendarIndex,
    setIsBulkOrder,
    setNotifications,
    setPreviousDate,
    setSelectedDate,
    setSelectedDates,
    setSelectedZipCode,
    setTurnOnAutoship
} from './core/autoShipSlice';
import DeliveryAPI from '../../API/deliveryAPI';
import { selectToken } from '../../features/user/userSlice';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { selectCustomerDetails } from '../../features/customer/customerDetailSlice';
import pickmyself from '../../assets/images/person-icon.svg';
import AutoShipSettings from './components/AutoShipSettings';
import AutoshipSelectPage from './components/AutoshipSelectPage';
import Loader from '../../components/Loader';
import OrdersAPI from '../../API/ordersAPI';

const AutoShipPage: React.FC = () => {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const orders = useSelector(selectOrders);
    const ordersLoaded = useSelector(selectOrdersLoadComplete);
    const getPrevDate = useSelector(selectPreviousDate);
    const selectedDate = useSelector(selectSelectedDate);
    const selectedDates = useSelector(selectSelectedDates);
    const selectedZipCode = useSelector(selectSelectedZipCode);
    const calendarIndex = useSelector(selectCalendarIndex);
    const updatingOrder = useSelector(selectUpdatingOrder);
    const customer = useSelector(selectCustomer);
    const custLoading = useSelector(selectCustomerIsLoading);
    const products = useSelector(selectProducts);
    const coupon = useSelector(selectCartCoupon);
    const shippingMethods = useSelector(selectShippingMethods);
    const notifications = useSelector(selectNotifications);
    const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
    const cartItems = useSelector(selectCartItems);
    const dietary = useSelector(selectAutoShipData);
    const isBulkOrder = useSelector(selectIsBulkOrder);
    const customerDetails = useSelector(selectCustomerDetails);
    const [multiDates, setMultiDates] = useState<Date[]>([]);
    const [autoAddWeeks, setAutoAddWeeks] = useState(notifications?._auto_add_weeks === '1');
    const [isChangedAlready, setIsChangedAlready] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addCalendarBox, setAddCalendarBox] = useState(false);
    const [editCalendarBox, setEditCalendarBox] = useState(false);
    const [turnOffAutoship, setTurnOffAutoship] = useState(false);
    const [autoshipSetting, setAutoshipSetting] = useState(false);
    const [autoshipSelectPage, setAutoshipSelectPage] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState('');
    const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
    const cart = useMemo(() => {
        return new CartObj(cartItems);
    }, [cartItems]);
    const zip = selectedZipCode ? selectedZipCode :
        customer?.shipping?.postcode ? customer?.shipping?.postcode : customer?.billing?.postcode;
    const city = customer?.shipping?.city ? customer?.shipping?.city : customer?.billing?.city;
    const processingOrders = orders.filter((order: any) => {
        const orderObj = new OrderObj(order);
        return orderObj.data.status === 'autoship';
    });
    const autoshipFailed = orders.filter((order: any) => {
        const orderObj = new OrderObj(order);
        return orderObj.data.status === 'mmfailpayment';
    });
    let isShipping: ShippingMethodObj[] = [];
    const prevOrderDate = selectedDate && (selectedDate !== null || selectedDate !== undefined)
        ? new Date(selectedDate) : new Date();
    const prevLocation = location.state && location.state.pathName;
    const UpdateDateAvailable = location.state && location.state.changeDate === true;
    const customerUserData = customerDetails?.user_data;
    const customerImage = customerUserData?.imageUrl;
    const imageUrl = customerImage && customerImage.includes('userdefault.png') ? pickmyself : customerImage;
    const billing_info = Object.keys(customer).length > 0 ? customer.billing : {};
    const billing = {
        first_name: !!billing_info.first_name && billing_info.first_name.trim(),
        last_name: !!billing_info.last_name && billing_info.last_name.trim(),
        address_1: !!billing_info.address_1 && billing_info.address_1.trim(),
        city: !!billing_info.city && billing_info.city.trim(),
        state: !!billing_info.state && billing_info.state.trim(),
        postcode: !!billing_info.postcode && billing_info.postcode.trim(),
        country: 'US',
        phone: !!billing_info.phone && billing_info.phone.trim(),
        email: !!billing_info.email && billing_info.email.trim()
    };
    const anyNullOrEmpty = Object.values(billing).some(value => value === false);
    const editAddressModal = anyNullOrEmpty && !custLoading;
    const order = OrderObj.findRecentOrder(orders);
    const orderDate = order?.getDeliveryDateTime();
    const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
        order?.data.billing.postcode ? order?.data.billing.postcode : zip;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const getAddBox = localStorage.getItem('autoship_add_calendar_box');
                const getEditBox = localStorage.getItem('autoship_edit_calendar_box');
                if (getAddBox === null || getAddBox === 'true') {
                    setAddCalendarBox(true);
                }
                if (getEditBox === null || getEditBox === 'true') {
                    setEditCalendarBox(true);
                }
                if (token) {
                    if (!isBulkOrder) {
                        await dispatch(resetOrders());
                    }
                    await dispatch(loadOrders({ token, isAutoship: true, customerId: customer.id }));
                }
            } catch (e: any) {
                console.log("Error while listing Orders: ", e);
            } finally {
                dispatch(setIsBulkOrder(false));
                setIsLoading(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isAutoshipPage = location.pathname.startsWith('/autoship');
        const isAutoshipStart = location.search.startsWith('?start=true');
        if (isAutoshipPage && isAutoshipStart && customer.id && ordersLoaded) {
            handleTurnOnAutoship();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, customer.id, ordersLoaded])

    useEffect(() => {
        if (customerImage && !customerImage.includes('userdefault.png')) {
            const elements = document.querySelectorAll<HTMLElement>('.pickformyself-autoship');
            const isMobile = window.innerWidth <= 575;
            elements.forEach((element) => {
                const pseudoElement = document.createElement('div');
                pseudoElement.classList.add('pseudo-element');

                pseudoElement.style.position = 'absolute';
                pseudoElement.style.width = isMobile ? '20px' : '26px';
                pseudoElement.style.height = isMobile ? '20px' : '26px';
                pseudoElement.style.borderRadius = '50%';
                pseudoElement.style.backgroundImage = `url(${imageUrl})`;
                pseudoElement.style.bottom = '0';
                pseudoElement.style.left = '50%';
                pseudoElement.style.transform = 'translateX(-50%)';
                pseudoElement.style.backgroundSize = 'contain';
                pseudoElement.style.backgroundRepeat = 'no-repeat';

                element.appendChild(pseudoElement);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    useEffect(() => {
        getShippingMethodDates(zip);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zip])

    useEffect(() => {
        const availableDatesFromAPI = deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]);
        if (shippingMethodbyZip.length > 0 && zip && !isLoading) {
            const findIndex = (calendarIndex && calendarIndex !== '') ? Number(calendarIndex) : selectedDate === null ?
                availableDatesFromAPI[0] : prevOrderDate?.getDay();
            const currIndex = availableDatesFromAPI.includes(findIndex) ? findIndex : availableDatesFromAPI[0];
            if (!calendarIndex || calendarIndex === '') {
                dispatch(setCalendarIndex(currIndex));
            }
            handleAutoAddWeeks(currIndex, autoAddWeeks);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingMethodbyZip, isLoading])

    const isDeliveryDay = (date: Date) => {
        const today = new Date();
        const blockedDates = deliveryInfo.getBlockedDates(city, zip);
        const updateDate = (location.state && location.state.date) ? new Date(location.state.date) : null;

        const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());
        const toDateObject = (d: any) => isValidDate(d) ? d : new Date(d);

        const hasDeadlinePassed = (day: number, deadlineHour: number) => {
            if (today.getDay() === day) {
                const deadline = new Date(today);
                deadline.setHours(deadlineHour, 0, 0, 0);
                return today > deadline;
            }
            return false;
        };

        if (hasDeadlinePassed(1, 22) || hasDeadlinePassed(4, 22)) {
            if (date.getDay() === 1 || date.getDay() === 4) {
                return false;
            }
        }

        if (updateDate !== null && (
            (multiDates && multiDates.some(d => toDateObject(d).toDateString() === date.toDateString())) ||
            (selectedDates && selectedDates.some((d: any) => toDateObject(d).toDateString() === date.toDateString()))
        )) {
            return false;
        }

        if (shippingMethodbyZip[0]?.data.ups_delivery_method === true) {
            const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
                const blockedDay = toDateObject(blockedDate).getDay();
                return blockedDay === 2;
            });

            if (isDisabledTuesday.length > 0) {
                let isNextWednesdayEnabled = false;

                isDisabledTuesday.forEach((tuesday: any) => {
                    const disabledTuesday = toDateObject(tuesday);
                    const nextWednesday = new Date(tuesday);
                    nextWednesday.setDate(disabledTuesday.getDate() + 1);

                    if (date.toDateString() === nextWednesday.toDateString()) {
                        isNextWednesdayEnabled = true;
                    }
                });

                if (isNextWednesdayEnabled) {
                    return true;
                }
            }

            if (today.getDay() === 2 || today.getDay() === 3) {
                const nextThursday = new Date(today);
                const nextFriday = new Date(today);
                nextThursday.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
                nextFriday.setDate(today.getDate() + ((5 + 7 - today.getDay()) % 7));
                if (date.toDateString() === nextThursday.toDateString() || date.toDateString() === nextFriday.toDateString()) {
                    return false;
                }
            } else if (today.getDay() > 4 || today.getDay() === 1 || today.getDay() === 0) {
                const nextTuesday = new Date(today);
                nextTuesday.setDate(today.getDate() + ((2 + 7 - today.getDay()) % 7));
                if (date.toDateString() === nextTuesday.toDateString()) {
                    return false;
                }
            }

            return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
        } else {
            return deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]).includes(date.getDay());
        }
    };

    const getWeek = (date: Date): string => {
        const onejan = new Date(date.getFullYear(), 0, 1);
        const dayOffset = (date.getDay() + 6) % 7;
        const weekNumber = Math.ceil(((date.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() - dayOffset + 1) / 7);
        return `${date.getFullYear()}-W${weekNumber}`;
    };

    const handleDateChange = async (date: [Date | null, Date | null], event: React.SyntheticEvent<any, Event> | undefined) => {
        const selecteDate = date[0];
        if (selecteDate) {
            dispatch(setSelectedDate(selecteDate));
        }

        const updateDate = (location.state && location.state.date) ? location.state.date : null;
        if (updateDate && selecteDate && updateDate.toDateString() !== selecteDate.toDateString()) {
            let updateDates: Date[] = [];

            if (!isChangedAlready) {
                setIsChangedAlready(true);
                updateDates = multiDates.filter((d: Date) => d.toDateString() !== updateDate.toDateString());
                updateDates.push(selecteDate);
            } else {
                updateDates = [...multiDates.slice(0, -1), selecteDate];
            }

            setMultiDates(updateDates);
        } else {
            const getCurrentOrder = processingOrders.filter((order: { id: any; }) => {
                const orderObj = OrderObj.findOrder(processingOrders, Number(order.id));
                const getDate = orderObj?.getDeliveryDateTime();
                return getDate && selecteDate && getDate.getTime() === new Date(selecteDate).getTime();
            });

            const failedOrders = autoshipFailed.filter((order: { id: any; }) => {
                const orderObj = OrderObj.findOrder(autoshipFailed, Number(order.id));
                const getDate = orderObj?.getDeliveryDateTime();
                return getDate && selecteDate && getDate.getTime() === new Date(selecteDate).getTime();
            });

            if (getCurrentOrder.length > 0 && failedOrders.length <= 0) {
                setCurrentOrderId(getCurrentOrder[0].id);
                setAutoshipSelectPage(true);
            } else if (failedOrders.length <= 0) {
                setAutoshipSelectPage(true);
            }
        }
        if (processingOrders.length > 0) {
            dispatch(setTurnOnAutoship(false));
        }
    };

    const getDayClassNames = (date: Date) => {
        const getBlockedDates = deliveryInfo?.getBlockedDates('', zip);
        const isBlockedDates = getBlockedDates?.some((blockedDate: Date) => blockedDate.toDateString() === date.toDateString());
        const availableDates = deliveryInfo?.getStartDate(zip) < date && addMonths(new Date(), 2) > date;
        const updateDate = (location.state && location.state.date) ? location.state.date : null;
        const updateDateIncluded = updateDate && selectedDate && new Date(selectedDate).toDateString() === date.toDateString();
        const ordersDateString = format(date, 'EEE, MMM d');
        const processing = processingOrders.filter((order: any) => {
            const orderObj = new OrderObj(order);
            return orderObj.getDeliveryDate() === ordersDateString;
        });

        const pickForMyself = processing.some((order: any) => {
            const metaData = order.meta_data.find((meta: any) =>
                meta.key === '_autoship_order_type' && meta.value === 'pick_for_myself'
            );
            return !!metaData;
        });

        const pickForMe = processing.some((order: any) => {
            const metaData = order.meta_data.find((meta: any) =>
                meta.key === '_autoship_order_type' && meta.value === 'pick_for_me'
            );
            return !!metaData;
        });

        if (autoshipFailed.length > 0) {
            const failedPayments = autoshipFailed.filter((order: any) => {
                const orderObj = new OrderObj(order);
                return orderObj.getDeliveryDate() === ordersDateString;
            });
            if (failedPayments.length > 0) {
                return 'available-deliverydays';
            }
        }

        if (updateDate && updateDate.toDateString() === date.toDateString()) {
            return 'editable-deliverydays';
        }

        if (updateDateIncluded) {
            return 'auto-deliverydays';
        }

        if (processing.length > 0) {
            if (pickForMyself) {
                return 'pickformyself-autoship';
            } else if (pickForMe) {
                return 'pickforme-autoship';
            }
        }

        if (isDeliveryDay(date) && !isBlockedDates && availableDates) {
            const curreWeek = getWeek(new Date());
            const selectedWeek = getWeek(new Date(date));
            if (!updateDate && selectedWeek !== curreWeek) {
                return 'available-add-deliverydays';
            } else {
                return 'available-deliverydays';
            }
        }

        return '';
    };

    const getShippingMethodDates = (zipCode: any) => {
        let matchedMethods = [];

        let hasFreeDelivery = false;

        for (const method of shippingMethods) {
            const sm = new ShippingMethodObj(method);
            if (sm.isMatch(zipCode, cart, products, coupon, customer)) {
                if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
                    hasFreeDelivery = false;
                    matchedMethods.push(sm);
                } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
                    if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
                        hasFreeDelivery = true;
                        matchedMethods.push(sm);
                    } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
                        matchedMethods.push(sm);
                        if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
                            isShipping.push(sm);
                        }
                    }
                }
            }
        }
        const UPSshipping = matchedMethods.filter(sm => sm.data.ups_delivery_method === true);

        if (hasFreeDelivery) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
        }

        if (cart.isEmpty()) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title !== "Free Email Delivery"));
        }

        if (UPSshipping.length > 0) {
            setShippingMethodByZip(UPSshipping);
        } else {
            setShippingMethodByZip(matchedMethods);
        }
    }

    const handleAutoAddWeeks = (index: number, isAutoAddWeek: boolean) => {
        const today = new Date();

        if (prevOrderDate === null || prevOrderDate === undefined) {
            setMultiDates([]);
        }

        const setDates: Date[] = [];
        const maxDeliveryDate = addMonths(new Date(), 2);
        let currentDate = new Date(today);

        currentDate.setDate(today.getDate() + (index - today.getDay() + 7) % 7);
        const getBlockedDates = deliveryInfo.getBlockedDates('', zip);

        while (currentDate <= maxDeliveryDate && setDates.length < 8) {
            if (index === currentDate.getDay() && isDeliveryDay(currentDate) &&
                currentDate.toDateString() !== today.toDateString()) {
                if (!getBlockedDates.some((blockedDate: Date) =>
                    blockedDate.toDateString() === currentDate.toDateString())) {
                    if ((getPrevDate && getPrevDate instanceof Date &&
                        getPrevDate.toDateString() !== currentDate.toDateString()) &&
                        getPrevDate < currentDate) {
                        setDates.push(new Date(currentDate));
                    } else {
                        setDates.push(new Date(currentDate));
                    }
                }
            }
            currentDate.setDate(currentDate.getDate() + 7);
        }

        const weekOfAutoShipOrders = new Set<string>();
        const setIncludedDates: Date[] = [];

        processingOrders.forEach((order: any) => {
            const orderObj = order.id ? OrderObj.findOrder(orders, Number(order.id)) : null;
            const metaData = order.meta_data.find((meta: any) => meta.key === '_delivery_date');
            if (metaData && metaData.value) {
                const orderDate = orderObj?.getDeliveryDateTime();
                const orderDeliveryDate = orderDate ? orderDate : new Date(metaData.value);
                const selectedWeek = getWeek(orderDeliveryDate);

                if (!getBlockedDates.some((blockedDate: Date) => blockedDate.toDateString() === orderDeliveryDate.toDateString())) {
                    setIncludedDates.push(orderDeliveryDate);
                    weekOfAutoShipOrders.add(selectedWeek);
                }
            }
        });

        const filteredDates = setDates.filter((date: Date) => {
            const week = getWeek(date);
            return !weekOfAutoShipOrders.has(week);
        });

        if (getPrevDate && getPrevDate instanceof Date) {
            const filterIncludedDates = filteredDates.filter((currDate: Date) => getPrevDate.toDateString() !== currDate.toDateString())
            setIncludedDates.push(...filterIncludedDates);
        } else {
            setIncludedDates.push(...filteredDates);
        }

        if ((selectedDate === null || prevOrderDate === null) &&
            (selectedDate !== setDates[0] ||
                prevOrderDate !== setDates[0])) {
            dispatch(setSelectedDate(setDates[0]));
        }
        const updateDate = (location.state && location.state.date) ? new Date(location.state.date) : null;

        if (!updateDate) {
            setMultiDates(setIncludedDates);
            dispatch(setSelectedDates(setIncludedDates));
        } else if (selectedDates) {
            setMultiDates(selectedDates);
        }

        if ((!dietary._autoship_meals_per_order || processingOrders.length <= 0) &&
            !isBulkOrder && !isLoading && !custLoading && !updatingOrder) {
            navigate('/autoship/settings');
        }
    };

    const handleApplyChanges = async () => {
        const updateDate = location.state?.date || null;
        if (!selectedDate || !updateDate) {
            dispatch(setSelectedDate(new Date(selectedDate)));
            dispatch(setSelectedDates(multiDates));
            navigate('/autoship', { state: { isChanged: true } });
            return;
        }

        const ordersDateString = format(updateDate, 'EEE, MMM d');
        const processing = processingOrders.filter((order: any) => {
            const orderObj = new OrderObj(order);
            return orderObj.getDeliveryDate() === ordersDateString;
        });

        if (processing.length === 0) {
            dispatch(setSelectedDate(new Date(selectedDate)));
            dispatch(setSelectedDates(multiDates));
            navigate('/autoship', { state: { isChanged: true } });
            return;
        }

        const order = processing[0];
        const deliveryDate = new Date(selectedDate).getFullYear().toString() + '-' +
            ('0' + (new Date(selectedDate).getMonth() + 1)).slice(-2) + '-' +
            ('0' + new Date(selectedDate).getDate()).slice(-2);

        dispatch(setUpdatingOrder(true));
        try {
            const response = await DeliveryAPI.updateOrderDeliveryDate(token, order.id, customer.id, deliveryDate);
            OrdersAPI.createOrderNote(token, order.id, "Customer changed delivery date.");
            await dispatch(updateOrderDeliveryDate({ order_id: order.id, delivery_date: response }));
        } catch (error) {
            console.log(error);
        } finally {
            const updateDate = (location.state && location.state.date) ? location.state.date : null;
            if (updateDate && new Date(selectedDate) && updateDate.toDateString() !== new Date(selectedDate).toDateString()) {
                const updateDates = multiDates.filter((d: Date) => d.toDateString() !== updateDate.toDateString());
                dispatch(setSelectedDate(new Date(selectedDate)));
                dispatch(setSelectedDates([...updateDates, selectedDate]));
            }
            dispatch(setUpdatingOrder(false));
            await dispatch(loadOrders({ token, isAutoship: true, customerId: customer.id }));
            navigate(prevLocation ? prevLocation : '/autoship', { state: { isChanged: true } });
        }
    };

    const handleCancleChanges = () => {
        setMultiDates(selectedDates ? selectedDates : []);
        dispatch(setSelectedDate(location?.state?.date));
        dispatch(setSelectedDates(selectedDates));
        navigate(prevLocation ? prevLocation : '/autoship', { state: { isChanged: location?.state?.isChanged } });
    }

    const handleAutoAddWeeksChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked ? '1' : '';
        setIsBusy(true);
        setAutoAddWeeks(e.target.checked);
        AutoShipAPI.postUserNotifications({
            userId: customer.id,
            autoshipAutoAddWeeks: checked,
            autoshipEmailNotifications: notifications._autoship_email_notifications,
            autoshipTextMsgNotifications: notifications._autoship_text_msg_notifications,
        }).then((result) => {
            dispatch(setNotifications(result.data));
            const availableDatesFromAPI = deliveryInfo.getDeliveryDays(city, zip, shippingMethodbyZip[0]);
            const findIndex = selectedDate === null ?
                availableDatesFromAPI[0] : new Date(selectedDate)?.getDay();
            const currIndex = availableDatesFromAPI.includes(findIndex) ? findIndex : availableDatesFromAPI[0];
            handleAutoAddWeeks(currIndex, e.target.checked);
        }).finally(() => {
            setIsBusy(false);
        });
    }

    const handleCrossIcon = () => {
        if (UpdateDateAvailable) {
            localStorage.setItem('autoship_edit_calendar_box', 'false');
            setEditCalendarBox(false);
        } else {
            localStorage.setItem('autoship_add_calendar_box', 'false');
            setAddCalendarBox(false);
        }
    }

    const handleBoxIcon = () => {
        if (UpdateDateAvailable) {
            localStorage.setItem('autoship_edit_calendar_box', 'true');
            setEditCalendarBox(true);
        } else {
            localStorage.setItem('autoship_add_calendar_box', 'true');
            setAddCalendarBox(true);
        }
    }

    const handleTurnOffAutoship = async () => {
        setIsBusy(true);
        dispatch(setUpdatingOrder(true));
        try {
            const response = await AutoShipAPI.deleteAllAutoshipOrders(customer.id);
            if (response.status === 'success') {
                await dispatch(resetOrders());
                await dispatch(loadOrders({ token }));
                await dispatch(loadOrders({ token, isAutoship: true, customerId: customer.id }));
                setAutoAddWeeks(false);
                setTurnOffAutoship(false);
                dispatch(setTurnOnAutoship(false));
                navigate('/autoship/orders');
            }
        } catch (e: any) {
            console.log(e, "Error while turning off autoship");
        } finally {
            setIsBusy(false);
            dispatch(setUpdatingOrder(false));
        }
    }

    const handleTurnOnAutoship = async () => {
        setIsLoading(true);
        try {
            if (processingOrders.length <= 0) {
                await AutoShipAPI.postUserNotifications({
                    userId: customer.id,
                    autoshipAutoAddWeeks: '1',
                    autoshipEmailNotifications: '1',
                    autoshipTextMsgNotifications: '1',
                }).then((response) => {
                    if (response.status === "success") {
                        dispatch(setNotifications(response.data));
                    }
                });
            }
        } catch (e: any) {
            console.log(e, "Error while Turning on Autoship");
        } finally {
            if (processingOrders.length <= 0) {
                dispatch(setTurnOnAutoship(true));
                dispatch(setPreviousDate(orderDate));
                dispatch(setSelectedDate(orderDate));
                dispatch(setSelectedZipCode(zipCode));
            }
            navigate('/autoship');
            setIsLoading(false);
        }
    }

    return (
        <Container className='autoshipContainer'>
            {isLoading ?
                <Loader /> :
                <>
                    <Modal show={editAddressModal} onHide={() => navigate('/my-account')}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You haven't provided your billing or shipping details yet.
                                We need those details to start your Order Planner.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-dark"
                                disabled={updatingOrder}
                                onClick={() => navigate('/my-account/address', { state: { autoShip: true } })}>
                                {updatingOrder ?
                                    <>
                                        Please wait ...&nbsp;&nbsp;
                                        <Spinner animation="border" as="span" size="sm" />
                                    </> : 'Edit Address'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={autoshipSetting} onHide={() => setAutoshipSetting(false)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <AutoShipSettings />
                        </Modal.Body>
                    </Modal>
                    <Modal show={autoshipSelectPage} onHide={() => setAutoshipSelectPage(false)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <AutoshipSelectPage
                                orderId={currentOrderId}
                                autoshipSelectPage={autoshipSelectPage}
                                setAutoshipSelectPage={setAutoshipSelectPage}
                                setMultiDates={setMultiDates} />
                        </Modal.Body>
                    </Modal>
                    <Modal show={turnOffAutoship} onHide={() => setTurnOffAutoship(false)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Turning off AutoShip will cancel all future orders.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" disabled={updatingOrder} onClick={() => setTurnOffAutoship(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="outline-dark"
                                disabled={updatingOrder || isBusy}
                                onClick={handleTurnOffAutoship}>
                                {(updatingOrder || isBusy) ?
                                    <>
                                        Please wait ...&nbsp;&nbsp;
                                        <Spinner animation="border" as="span" size="sm" />
                                    </> : 'Okay'}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {UpdateDateAvailable ?
                        <div className='d-flex justify-content-center align-items-center autoshipPage pb-2'>
                            <span className='autoshipPageHeader'>UPDATE DELIVERY DATES</span>
                        </div> :
                        <div className='d-flex justify-content-center align-items-center autoshipPage pb-2'>
                            <span className='autoshipPageHeader'>Order Planner Calendar</span>
                        </div>}
                    {addCalendarBox && !UpdateDateAvailable &&
                        <div className='d-flex justify-content-center align-items-center box-alert'>
                            <Alert variant="secondary" className='text-center my-3'>
                                <FontAwesomeIcon className='info-icon' icon={faCircleInfo} size="sm" color={'green'} />
                                <span className='d-flex justify-content-center align-items-center alert-text'>
                                    Welcome to your personal order planner calendar. We have added 8 "Chef's Choice" orders to your calendar.
                                    You can edit each order by clicking on a solid green square or add additional orders by clicking (+) icon below
                                    each date.
                                </span>
                                <div className='settingclose'>
                                    <FontAwesomeIcon
                                        color='black'
                                        className='cursor-pointer'
                                        onClick={handleCrossIcon}
                                        icon={faClose}
                                    ></FontAwesomeIcon>
                                </div>
                            </Alert>
                        </div>}
                    {editCalendarBox && UpdateDateAvailable &&
                        <div className='d-flex justify-content-center align-items-center box-alert'>
                            <Alert variant="secondary" className='text-center my-3'>
                                <FontAwesomeIcon className='info-icon' icon={faCircleInfo} size="sm" color={'green'} />
                                <span className='d-flex justify-content-center align-items-center alert-text'>
                                    Want to change your delivery dates? Not a problem!
                                    Select one green square at a time and move it to your desired delivery date.
                                    Once you're finished, apply your changes!
                                </span>
                                <div className='settingclose'>
                                    <FontAwesomeIcon
                                        color='black'
                                        className='cursor-pointer'
                                        onClick={handleCrossIcon}
                                        icon={faClose}
                                    ></FontAwesomeIcon>
                                </div>
                            </Alert>
                        </div>}
                    <div className='option-col'>
                        <Row>
                            <Col>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="green-boxes box-part"></div>
                                    Current Orders
                                </span>
                            </Col>
                            {!UpdateDateAvailable &&
                                <Col>
                                    <span className='fs-12px d-flex align-items-center'>
                                        <div className="gray-boxes box-part plus-gray-box"></div>
                                        New Order
                                    </span>
                                </Col>}
                            {UpdateDateAvailable &&
                                <Col>
                                    <span className='fs-12px d-flex align-items-center'>
                                        <div className="yellow-boxes box-part"></div>
                                        Selected Order
                                    </span>
                                </Col>}
                            <Col>
                                <span className='fs-12px d-flex align-items-center'>
                                    <div className="gray-boxes box-part"></div>
                                    Dates&nbsp;&nbsp;&nbsp;&nbsp;
                                    {((UpdateDateAvailable && !editCalendarBox) || (!UpdateDateAvailable && !addCalendarBox)) &&
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            size="lg"
                                            className='cursor-pointer'
                                            onClick={handleBoxIcon}
                                        />
                                    }
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <div className='calender-box d-flex flex-column justify-content-center align-items-center pb-4'>
                        <div className='calenderwrapper'>
                            <DatePicker
                                autoComplete="date"
                                calendarStartDay={1}
                                filterDate={isDeliveryDay}
                                excludeDates={deliveryInfo.getBlockedDates('', zip)}
                                open
                                monthsShown={2}
                                minDate={deliveryInfo.getStartDate(zip)}
                                maxDate={addMonths(new Date(), 2)}
                                onChange={handleDateChange}
                                onFocus={e => { e.target.blur(); }}
                                disabledKeyboardNavigation
                                dayClassName={getDayClassNames}
                                inline
                                selectsRange
                            />
                            <div className={`${Window.isMobile() ? 'calenderMobileFooter' : 'calenderDeskFooter'}`}>
                                <div className='d-flex justify-content-center align-items-center tooltip-cal'>
                                    <Form.Check
                                        type="switch"
                                        className='mx-2 d-flex justify-content-center align-items-center'
                                        checked={autoAddWeeks}
                                        disabled={isBusy}
                                        onChange={(e: any) => handleAutoAddWeeksChange(e)}
                                    />
                                    <span className='fs-6'>{isBusy ?
                                        <>
                                            Auto Add Weeks&nbsp;&nbsp;
                                            <Spinner
                                                animation="border"
                                                as="span"
                                                size="sm"
                                            />
                                        </>
                                        : 'Auto Add Weeks'}
                                    </span>
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="top"
                                        overlay={

                                            <Tooltip id="tooltip-auto-add-weeks tooltip-cal" >
                                                Never run out of meals! Turn on "Auto Add Weeks" to keep your calendar filled with orders up to 8 weeks in advance & to ensure you always have fresh, delicious meals on hand.
                                            </Tooltip>
                                        }
                                    >
                                        <div className='text-black rounded-circle cursor-pointer ps-2'>
                                            <FontAwesomeIcon icon={faCircleInfo} size="sm" />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (location.state && location.state.changeDate === true) ?
                            <div className='d-flex justify-content-center align-items-center pb-4'>
                                <Button
                                    size="sm"
                                    variant="success"
                                    className='mx-3 text-white'
                                    disabled={isBusy || updatingOrder}
                                    onClick={handleApplyChanges}
                                >
                                    {updatingOrder ?
                                        <>
                                            APPLYING CHANGES ...&nbsp;
                                            <Spinner
                                                animation="border"
                                                as="span"
                                                size="sm"
                                            />
                                        </>
                                        : 'APPLY CHANGES'}
                                </Button>

                                <Button
                                    size="sm"
                                    variant="danger"
                                    className='mx-3 text-white'
                                    disabled={isBusy || updatingOrder}
                                    onClick={handleCancleChanges}
                                >
                                    CANCEL
                                </Button>
                            </div> :
                            <div className='d-flex btn-both-wrap justify-content-center align-items-center pb-4'>
                                <Button
                                    size="sm"
                                    variant="dark"
                                    disabled={isBusy}
                                    onClick={() => {
                                        setAutoshipSetting(true);
                                        if (processingOrders.length > 0) {
                                            dispatch(setTurnOnAutoship(false));
                                        }
                                    }}
                                >
                                    ORDER PLANNER SETTINGS
                                </Button>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    className='text-white'
                                    disabled={isBusy}
                                    onClick={() => setTurnOffAutoship(true)}
                                >
                                    TURN OFF ORDER PLANNER
                                </Button>
                            </div>
                    }
                </>}
        </Container>
    )
}

export default AutoShipPage;