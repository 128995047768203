import { ChangeEvent, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FormValues } from './interfaces';
import GooglePlace from '../GooglePlace';


export default function ShippingDetails() {
  const formik = useFormikContext<FormValues>();
  const [show, setShow] = useState(Boolean(formik.values.use_shipping));
  const streetRef = useRef<any>();
  const townRef = useRef<any>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      formik.setFieldValue('use_shipping', '1');
    } else {
      formik.setFieldValue('use_shipping', '');
    }
    setShow(e.target.checked);
  }
  return (
    <Card className='shipping-details my-3' body>
      <div className='d-flex justify-content-between my-4'>
        <span className='fs-3'>Ship to a different address?</span>
        <Form.Check
          type="switch"
          className='mx-2'
          value={formik.values.use_shipping}
          defaultChecked={show}
          onChange={handleChange}
        />
      </div>
      {show &&
        <div>
          <Row>
            <Col sm={12} lg={6}>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  id="shipping_first_name"
                  type="text"
                  isInvalid={Boolean(formik.errors.shipping_first_name)}
                  value={formik.values.shipping_first_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.shipping_first_name &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.shipping_first_name}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col sm={12} lg={6}>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  id="shipping_last_name"
                  type="text"
                  isInvalid={Boolean(formik.errors.shipping_last_name)}
                  value={formik.values.shipping_last_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.shipping_last_name &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.shipping_last_name}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Street address</Form.Label>
            <GooglePlace formik={formik} streetRef={streetRef} townRef={townRef} name={'shipping_address_1'}
              city={'shipping_city'} state={'shipping_state'} postcode={'shipping_postcode'}
            />
            {formik.errors.shipping_address_1 &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_address_1}
              </Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Control
              id="shipping_address_2"
              type="text"
              placeholder="Apartment, suite, unit etc. (optional)"
              isInvalid={Boolean(formik.errors.shipping_address_2)}
              value={formik.values.shipping_address_2}
              onChange={formik.handleChange}
            />
            {formik.errors.shipping_address_2 &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_address_2}
              </Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Town / City</Form.Label>
            <Form.Control
              id="shipping_city"
              ref={townRef}
              type="text"
              name="shipping_city"
              placeholder="Enter Town/Area"
              value={formik.values.shipping_city}
              onChange={formik.handleChange}

            />
            {formik.errors.shipping_city &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_city}
              </Form.Control.Feedback>}
          </Form.Group>
          <p>Delivery is to Residential addresses only. Non-residential deliveries
            will be canceled and are not refundable.
          </p>
          <Row>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>State</Form.Label>
                <Form.Select
                  id="shipping_state"
                  isInvalid={Boolean(formik.errors.shipping_state)}
                  value={formik.values.shipping_state}
                  onChange={formik.handleChange}
                >
                  <option value="">-- select a state --</option>
                  <option value="DC">District of Columbia</option>
                  <option value="DE">Delaware</option>
                  <option value="MD">Maryland</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="VA">Virginia</option>
                  <option value="WV">West Virginia</option>
                </Form.Select>
                {formik.errors.shipping_state &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.shipping_state}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Postcode / ZIP</Form.Label>
                <Form.Control
                  id="shipping_postcode"
                  type="text"
                  maxLength={10}
                  placeholder="Enter Postcode / ZIP"
                  value={formik.values.shipping_postcode}
                  onChange={formik.handleChange}
                />
                {formik.errors.shipping_postcode &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.shipping_postcode}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
        </div>
      }
    </Card >
  )
}