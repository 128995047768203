import { useLocation } from "react-router-dom"
import { Image } from 'react-bootstrap';
import circleLogo from '../assets/images/mm_circle_logo.png';
import logo from '../assets/images/mightymeals_logo.png';
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { selectEmbedded, selectLogoUrl } from "../features/referrals/referralsSlice";

export default function NavLogoMobile() {
  const location = useLocation();
  //const embedded = useSelector(selectEmbedded);
  const affiliateLogoUrl = useSelector(selectLogoUrl);

  return (
    <>
      {affiliateLogoUrl ? 
        <div className='d-flex gap-1'>
          <Image 
            className="mm-affiliate border-0" 
            src={circleLogo} 
            thumbnail={true}
            alt="MightyMeals Logo" 
          />
          <Image 
            className="border-0 mm-thumbnail" 
            src={affiliateLogoUrl} 
            thumbnail={true}
            alt="Affiliate Logo" 
          />
        </div>
      :
        <>
          {location.pathname.startsWith('/order') ?
            <Image 
              className="mm-logo border-0" 

              src={logo} 
              thumbnail={true}
              alt="MightyMeals Logo" 
            />
          :
            <img className="mm-logo" src={logo} alt="MightyMeals Logo" /> 
          } 
        </>
      } 
    </>
  )
}