import { useFormik, FormikErrors } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AutoShipAPI from '../core/autoshipAPI';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { selectAutoShipData, selectEditCouponLines, selectExistingCartItems, selectSelectedDate, selectSelectedDates, selectTurnOnAutoship, setAutoShipData, setExistingCartItems, setSelectedDates } from '../core/autoShipSlice';
import Select from 'react-select';
import ProductObj from '../../../features/products/productObj';
import { clearCart, selectCartCoupon, selectCartItems, selectCartUpdateInProgress, selectOrderInProgress, setCart, setOrderInProgress } from '../../../features/cart/cartSlice';
import { selectToken } from '../../../features/user/userSlice';
import { AutoshipValues } from '../core/interface';
import { format } from 'date-fns';
import { setUpdatingOrder, updateOrder } from '../../../features/orders/ordersSlice';
import { setPendingOrder } from '../../../features/checkout/checkoutSlice';
import { setPointsCoupon } from '../../../features/coupons/couponsSlice';
import { resetReferrals } from '../../../features/referrals/referralsSlice';
import OrdersAPI from '../../../API/ordersAPI';
import ShippingMethodObj from '../../../features/shipping/shippingMethodObj';
import CustomerObj from '../../../features/customer/customerObj';
import CartObj from '../../../features/cart/cartObj';
import { selectProducts } from '../../../features/products/productsSlice';
import CouponObj from '../../../features/coupons/couponObj';
import { selectShippingMethods } from '../../../features/shipping/shippingSlice';
import { selectIsMobileRoute } from '../../../features/mobile/mobileSlice';

interface Props {
    handleFirstAccordian: () => void;
}

const Diet: any = [
    { value: '', label: 'Select Diet' },
    { value: 'low-carb', label: 'Low Carb' },
    { value: 'low-sodium', label: 'Low Sodium' },
    { value: 'over-500-cal', label: 'Over 500 Calories' },
    { value: 'under-500-cal', label: 'Under 500 Calories' },
];

const MealPreferences: any = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'standard-menu', label: 'Standard Menu' },
    { value: 'bulk', label: 'Bulk' },
    { value: 'snacks', label: 'Snacks' },
    { value: 'desserts', label: 'Desserts' },
];

const DietaryRestrictions: any = [
    { value: 'dairy-free', label: 'Dairy-Free' },
    { value: 'nut-free', label: 'Nut-Free' },
    { value: 'gluten-free', label: 'Gluten-Free' },
    { value: 'not-spicy', label: 'Not-Spicy' },
    { value: 'shellfish-free', label: 'Shellfish-Free' },
    { value: 'soy-free', label: 'Soy-Free' },
    { value: 'wheat-free', label: 'Wheat-Free' },
    { value: 'sesame-free', label: 'Sesame-Free' },
    { value: 'vegan', label: 'Vegan' },
    { value: 'vegetarian', label: 'Vegetarian' },
];

export default function AutoshipDietaryPage({ handleFirstAccordian }: Props) {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDate = useSelector(selectSelectedDate);
    const selectedDates = useSelector(selectSelectedDates);
    const turnOnAutoship = useSelector(selectTurnOnAutoship);
    const token = useSelector(selectToken);
    const [busy, setBusy] = useState(false);
    const [errorMSG, setErrorMsg] = useState('');
    const custData = useSelector(selectCustomer);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cart = new CartObj(useSelector(selectCartItems));
    const products = useSelector(selectProducts);
    const dietary = useSelector(selectAutoShipData);
    const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
    const orderInProgress = useSelector(selectOrderInProgress);
    const shippingMethods = useSelector(selectShippingMethods);
    const ExistingCartItems = useSelector(selectExistingCartItems);
    const EditCouponLines = useSelector(selectEditCouponLines);
    const orderUpdateInProgress = useSelector(selectCartUpdateInProgress);
    const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};
    const AppURL = useSelector(selectIsMobileRoute);
    let isShipping: ShippingMethodObj[] = [];
    const isSelectedPickForMe = location.state && location.state.isSetting;
    const updateOrderId = params.orderId;
    const updateShippingId = location?.state && location?.state?.shippingId;
    const isNeedToChange = location?.state && location.state.changeToSelect === true;
    const isNotAutoshipSettingPage = !location.pathname.startsWith('/autoship/settings');

    const validateForm = (values: AutoshipValues) => {
        let errors: FormikErrors<AutoshipValues> = {};

        if (!values.autoship_meals_per_order.value) {
            errors.autoship_meals_per_order = { label: 'Number of meals per order is required', value: '' };
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            userId: custData.id,
            autoship_meals_per_order: { label: '', value: '' },
            autoship_diet: { label: '', value: '' },
            autoship_allergies: [],
            autoship_meal_preferences: [],
        },
        validate: validateForm,
        onSubmit: async (values) => {
            setErrorMsg('');
            setBusy(true);

            try {
                const userDiet = values.autoship_diet?.value || '';
                const response = await AutoShipAPI.postDietary({
                    userId: custData.id,
                    autoshipMealsPerOrder: Array.isArray(values.autoship_meals_per_order) ? '' : values.autoship_meals_per_order?.value || '',
                    autoshipDiet: userDiet,
                    autoshipAllergies: values.autoship_allergies.map((option: { value: any }) => option.value),
                    autoshipMealPreferences: values.autoship_meal_preferences.map((option: { value: any }) => option.value),
                });

                if (response.status === "success") {
                    dispatch(setAutoShipData(response.autoship_settings));

                    if ((isSelectedPickForMe || turnOnAutoship || updateOrderId) && response.order_items) {
                        const setItems = response.order_items.reduce((acc: any, items: any) => {
                            const product = new ProductObj(items.product);
                            const cartItemKey = product.data.id.toString();
                            acc[cartItemKey] = {
                                product_id: product.data.id,
                                product_qty: items.quantity,
                                product_price: Number(product.data.price),
                            };
                            return acc;
                        }, {});

                        await dispatch(setCart({
                            token: token,
                            cart_items: setItems,
                        }));
                    } else {
                        setErrorMsg(response.message);
                    }
                    if (updateOrderId) {
                        UpdateEditOrder();
                    } else {
                        handleFirstAccordian();
                    }
                } else {
                    setErrorMsg(response.message);
                }
            } catch (error: any) {
                console.error(error);
                setErrorMsg(error);
            } finally {
                setBusy(false);
            }
        },
    });

    useEffect(() => {
        if (dietary) {
            const allergiesFormatted = (dietary._autoship_allergies || []).map((allergy: any) => {
                const selectedAllergyOption = DietaryRestrictions.find((option: any) => option.value === allergy) || {};
                return { value: allergy, label: selectedAllergyOption.label || '' };
            }).filter((allergy: any) => allergy.value !== null && allergy.value !== '');

            const mealPreferencesFormatted = (dietary._autoship_meal_preferences || []).map((meals: any) => {
                const selectedMealPreferences = MealPreferences.find((option: any) => option.value === meals) || {};
                return { value: meals, label: selectedMealPreferences.label || '' };
            }).filter((meals: any) => meals.value !== null && meals.value !== '');

            const autoshipMealsPerOrder = dietary._autoship_meals_per_order !== undefined ? dietary._autoship_meals_per_order.toString() : '';

            formik.setValues({
                ...formik.values,
                autoship_meals_per_order: {
                    label: autoshipMealsPerOrder,
                    value: autoshipMealsPerOrder,
                },
                autoship_diet: dietary._autoship_diet,
                autoship_allergies: allergiesFormatted,
                autoship_meal_preferences: mealPreferencesFormatted,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dietary]);

    const handleSelectChange = (selected: any, selectedOptions: any) => {
        setErrorMsg('');
        formik.setFieldValue(selectedOptions.name, selected);
    };

    const handleCancelOrder = async () => {
        if (isSelectedPickForMe) {
            const selectedDateString = format(selectedDate, 'yyyy-MM-dd');
            const updatedDates = await selectedDates.filter((date: Date) => format(date, 'yyyy-MM-dd') !== selectedDateString);
            await dispatch(setSelectedDates(updatedDates));
            if (params.orderId) {
                dispatch(setUpdatingOrder(true));
                try {
                    const response = await OrdersAPI.updateOrder(token, Number(params.orderId), { status: 'mmcancelled' });

                    if ('id' in response) {
                        dispatch(updateOrder(response));
                    }

                } catch (error: any) {
                    console.log(error.message);
                } finally {
                    dispatch(setUpdatingOrder(false));
                }
            }
        }
        navigate('/autoship');
    }

    const getMatchedMethods = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isShipping = [];
        const zip = shipping_info.postcode ? shipping_info.postcode : billing_info.postcode;

        let matchedMethods = [];

        let hasFreeDelivery = false;

        for (const method of shippingMethods) {
            const sm = new ShippingMethodObj(method);
            if (sm.isMatch(zip, cart, products, cartCoupon, customer)) {

                if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
                    hasFreeDelivery = false;
                    matchedMethods.push(sm);
                } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
                    if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
                        hasFreeDelivery = true;
                        matchedMethods.push(sm);
                    } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
                        matchedMethods.push(sm);
                        if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
                            isShipping.push(sm);
                        }
                    }
                }

            }
        }

        if (hasFreeDelivery) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
        }


        return matchedMethods;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billing_info.postcode, shipping_info.postcode, shippingMethods, cart, products, cartCoupon, customer]);

    const UpdateEditOrder = async () => {
        try {
            const shippingCode = getMatchedMethods();
            const shippingMethod = ShippingMethodObj.getById(
                shippingMethods,
                shippingCode[0].data.ID
            );

            dispatch(setOrderInProgress(true));
            const transformedExistingCartItems = Object.keys(ExistingCartItems).map(key => ({
                ...ExistingCartItems[key],
                product_qty: 0,
                quantity: 0
            }));

            const lineItems = [
                ...transformedExistingCartItems,
                ...cart.getLineItems(products)
            ];

            const orderData: Record<string, any> = {
                line_items: lineItems,
                coupon_lines: [{ "code": EditCouponLines[0].code }],
            };

            if (shippingMethod) {
                orderData.shipping_lines = [{
                    "id": updateShippingId,
                    "method_id": 'flat_rate',
                    "method_title": shippingMethod.data.title,
                    "total": shippingMethod.data.cost
                }];
            }

            if (isNeedToChange) {
                orderData.meta_data = [{
                    'key': '_autoship_order_type',
                    'value': "pick_for_me"
                }];
            }

            const response = await OrdersAPI.updateOrder(token, Number(updateOrderId), orderData);
            if ('id' in response) {
                dispatch(updateOrder(response));
                dispatch(clearCart(token));
                dispatch(setExistingCartItems({}));
                dispatch(setPendingOrder({}));
                dispatch(setPointsCoupon(null));
                dispatch(resetReferrals());
                navigate(isNeedToChange ? '/autoship' : `/autoship/orders/${updateOrderId}${AppURL}`);
            }

        } catch (e) {
            console.error("Error While Updating Order: ", e);
        } finally {
            dispatch(setOrderInProgress(false));
        }
    };

    const dietOption = (dietary: any) => {
        if (!dietary) return undefined;
        const dietValue = dietary.value ? dietary.value : dietary;
        const dietFilter = Diet?.filter((diet: any) => diet.value === dietValue);
        return dietFilter;
    }    

    return (
        <Form onSubmit={formik.handleSubmit}>
            {isSelectedPickForMe && errorMSG &&
                <Alert variant="danger" className='my-3 w-auto'>
                    <div dangerouslySetInnerHTML={{ __html: errorMSG }}></div>
                </Alert>}
            <Row>
                <Col sm={isNotAutoshipSettingPage ? 12 : 6} md={isNotAutoshipSettingPage ? 12 : 4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Diet</Form.Label>
                        <div className='d-flex'>
                            <Select
                                className="form-textbox w-100"
                                name='autoship_diet'
                                value={dietOption(formik?.values?.autoship_diet) || null}
                                onChange={handleSelectChange}
                                placeholder="Select Diet"
                                options={Diet}
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={isNotAutoshipSettingPage ? 12 : 6} md={isNotAutoshipSettingPage ? 12 : 4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Allergies / Dietary Restrictions</Form.Label>
                        <div className='d-flex'>
                            <Select
                                className="form-textbox w-100"
                                name='autoship_allergies'
                                value={formik.values.autoship_allergies}
                                onChange={handleSelectChange}
                                options={DietaryRestrictions}
                                placeholder="Select Allergies/Dietary Restrictions"
                                isMulti
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={isNotAutoshipSettingPage ? 12 : 6} md={isNotAutoshipSettingPage ? 12 : 4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Types of Meal Preferences</Form.Label>
                        <div className='d-flex'>
                            <Select
                                className="form-textbox w-100"
                                name='autoship_meal_preferences'
                                value={formik.values.autoship_meal_preferences}
                                onChange={handleSelectChange}
                                options={MealPreferences}
                                placeholder="Select Meal Preferences"
                                isMulti
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col sm={isNotAutoshipSettingPage ? 12 : 6} md={isNotAutoshipSettingPage ? 12 : 4} className="mb-4">
                    <Form.Group className="form-group">
                        <Form.Label>Number of meals per order</Form.Label>
                        <div className='d-flex'>
                            <Select
                                className="form-textbox w-100"
                                name="autoship_meals_per_order"
                                placeholder="Select Number Of Meals"
                                value={formik.values.autoship_meals_per_order.value && formik.values.autoship_meals_per_order}
                                onChange={handleSelectChange}
                                options={([...Array(94).keys()].map((number) => (
                                    { value: `${number + 6}`, label: `${number + 6}` }
                                )) as any)}
                            />
                        </div>
                        {formik?.touched?.autoship_meals_per_order && formik?.errors?.autoship_meals_per_order &&
                            <Form.Control.Feedback type="invalid">
                                {formik?.errors?.autoship_meals_per_order.label}
                            </Form.Control.Feedback>}
                    </Form.Group>
                </Col>
            </Row>
            <div className='mt-2 d-flex justify-content-end flex-wrap btn-group btn-order-group'>
                <div className='col-md-auto' >
                    <Button
                        className='text-white bg-danger'
                        disabled={busy || orderInProgress || orderUpdateInProgress}
                        onClick={handleCancelOrder}
                    >
                        {isSelectedPickForMe ? 'CANCEL THIS ORDER' : 'CANCEL'}
                    </Button>
                </div>
                {isSelectedPickForMe &&
                    <div className='col-md-auto'>
                        <Button
                            disabled={busy || orderInProgress || orderUpdateInProgress}
                            onClick={() => navigate('/autoship', {
                                state: {
                                    changeDate: true,
                                    date: selectedDate
                                }
                            })}
                        >
                            CHANGE DATE
                        </Button>
                    </div>}
                <div className='col-md-auto'>
                    {isSelectedPickForMe ?
                        <Button
                            variant="success"
                            className='text-white w-100'
                            type="submit"
                            disabled={busy || orderInProgress || orderUpdateInProgress}
                        >
                            {(busy || orderInProgress || orderUpdateInProgress) ?
                                <>
                                    PLACING ORDER... &nbsp;&nbsp;
                                    <Spinner
                                        animation="border"
                                        as="span"
                                        size="sm"
                                    />
                                </> : 'SAVE & PLACE ORDER'}
                        </Button> :
                        <Button
                            variant="success"
                            className='text-white w-100'
                            type="submit"
                            disabled={busy}
                        >
                            {busy ?
                                <>
                                    UPDATING... &nbsp;&nbsp;
                                    <Spinner
                                        animation="border"
                                        as="span"
                                        size="sm"
                                    />
                                </> : 'UPDATE & CONTINUE'}
                        </Button>}
                </div>
            </div>
        </Form>
    )
}
