import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../customer/customerSlice';
import { selectProducts } from '../products/productsSlice';
import CouponObj from '../coupons/couponObj';
import CartObj from './cartObj';
import CustomerObj from '../customer/customerObj';

interface Props {
  cart: CartObj;
  cartCoupon: CouponObj | null;
}

export default function OrderSummary({ cart, cartCoupon }: Props) {
  const products = useSelector(selectProducts);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const isGiftCardProduct = (cart.hasProductWithCategory('gift-card', products) ||
    cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
    cart.hasProductWithCategory('giftcard-donation', products));

  return (
    <div className='order-summary'>
      <Row className="py-1">
        <Col className='order-summary-label'>Order Summary</Col>
      </Row>
      <Row className="py-1">
        <Col xs={8}>{cart.getItemCount()} item(s)</Col>
        <Col xs={4}>{isGiftCardProduct ?
          cart.getSubtotal(null, null, null, true) :
          cart.getSubtotal(null, products, customer, true)}
        </Col>
      </Row>
      {cartCoupon &&
        ['fixed_cart', 'fixed_product', 'percent', 'percent_product'].includes(cartCoupon.data.discount_type) &&
        <Row className="py-1">
          <Col xs={8}>Discounts:</Col>
          <Col xs={4}>
            {cartCoupon.getCartSubtotalDiscount(cart, products, true)}
          </Col>
        </Row>
      }
      {cartCoupon && cartCoupon.data.discount_type === 'smart_coupon' &&
        <Row className="py-1">
          <Col xs={8}>Discounts:</Col>
          <Col xs={4}>
            {cartCoupon.getSmartCouponAmount(
              cart.getSubtotal(null, null, null) as number, true)}
          </Col>
        </Row>
      }
      <Row className="py-1">
        <Col xs={8} className="subtotal-label">
          Subtotal
        </Col>
        <Col xs={4} className="fw-bold">
          {isGiftCardProduct && cartCoupon ?
            (cart.getSubtotal(cartCoupon, products, null, true)) :
            isGiftCardProduct ? cart.getSubtotal(null, null, null, true) :
              cartCoupon ?
                cart.getSubtotal(cartCoupon, products, customer, true) :
                cart.getSubtotal(null, products, customer, true)}
        </Col>
      </Row>
    </div>
  );
}