import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikErrors, useFormik } from 'formik';
import { Button, Form, Spinner } from "react-bootstrap";
import { getCouponByCode } from '../../API/coupons';
import { useDispatch } from 'react-redux';
import { setCartCoupon } from '../cart/cartSlice';
import { selectToken } from '../user/userSlice';
import { selectCartItems } from '../cart/cartSlice';
import CouponObj from './couponObj';
import CartObj from '../cart/cartObj';
import { selectCustomer, selectCustomerEmail } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import Window from '../../utils/Window';
import { selectProducts, selectProductsPagesLoaded, selectProductsTotalPages } from '../products/productsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectIsMobileRoute } from '../mobile/mobileSlice';
import { setIsOrderChanged } from '../../pages/AutoshipPages/core/autoShipSlice';

interface FormValues {
  couponCode: string;
}

interface Props {
  mealCountDiscount?: string;
}

export default function CouponForm({ mealCountDiscount }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const email = useSelector(selectCustomerEmail);
  const productPagesLoaded: number = useSelector(selectProductsPagesLoaded);
  const totalProductPages = useSelector(selectProductsTotalPages);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customer = new CustomerObj(useSelector(selectCustomer));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cart = new CartObj(useSelector(selectCartItems));
  const products = useSelector(selectProducts);
  const [errorMsg, setErrorMsg] = useState('');
  const [busy, setBusy] = useState(false);
  const AppURL = useSelector(selectIsMobileRoute);
  const placeholder = Window.isMobile() ? 'Enter Promo Code' : 'Enter Gift Card or Discount';
  const queryParams = new URLSearchParams(location.search);
  const couponCodeQuery = queryParams.get("coupon-code");
  const productCodeQuery = queryParams.get("product_id");
  const [couponCode, setCouponCode] = useState<any>('');
  const isMobileDevice = location.search.includes('?utm_source=app');

  useEffect(() => {
    if (couponCodeQuery?.length && (couponCodeQuery !== "" || couponCodeQuery !== null)) {
      formik.setFieldValue("couponCode", couponCodeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCodeQuery])

  useEffect(() => {
    const applyCouponCode = (code: any) => {
      setErrorMsg('');
      setBusy(true);
      getCouponCodeFromQuery(code);
    };

    if (!productCodeQuery) {
      if (couponCode.length) {
        let coupon = new CouponObj(couponCode[0]);
        applyCouponCode(coupon?.data?.code);
      } else if (mealCountDiscount) {
        applyCouponCode(mealCountDiscount);
      } else if (couponCodeQuery?.length && (couponCodeQuery !== "" || couponCodeQuery !== null)) {
        applyCouponCode(couponCodeQuery);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items, mealCountDiscount, couponCodeQuery, totalProductPages, productPagesLoaded, productCodeQuery]);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.couponCode) {
      errors.couponCode = "Required";
    } else if (!/^\S+$/.test(values.couponCode)) {
      errors.couponCode = "Invalid";
    }
    return errors;
  }

  const getCouponCodeFromQuery = (couponCode: any) => {
    getCouponByCode(
      couponCode
    ).then((coupons: Array<Record<string, any>>) => {
      if (!coupons.length || coupons.length === 0) {
        setErrorMsg('Coupon "' + couponCode + '" is invalid.');
        return;
      }
      setCouponCode(coupons)
      let coupon = new CouponObj(coupons[0]);
      let validationResult = coupon.validate(email, customer, cart, products);
      if (validationResult.error) {
        let errorMessage = validationResult.message;

        const isHtml = /<\/?[a-z][\s\S]*>/i.test(errorMessage);

        if (isHtml) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = errorMessage;
          errorMessage = tempDiv.textContent || tempDiv.innerText;
        }

        setErrorMsg(errorMessage);
        return;
      }
      dispatch(setCartCoupon({ token: token, coupon: coupons[0] }));
      dispatch(setIsOrderChanged(false));
      if (location.pathname === '/checkout/') {
        navigate(`/checkout${AppURL}`)
      }
    }).catch((e) => {
      console.error(e);
      setErrorMsg('An unexpected error has occurred. Please try again later.');
    }).finally(() => {
      setBusy(false);
      if (couponCodeQuery === '10offnextorder' && location.pathname === '/order/') {
        navigate(`/order/${AppURL}`)
        setErrorMsg('')
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      couponCode: ''
    },
    validate,
    onSubmit: values => {
      if (values.couponCode.length && (values.couponCode !== "" || values.couponCode !== null)) {
        setErrorMsg('');
        setBusy(true);
        getCouponCodeFromQuery(values.couponCode);
      }
    }
  });
  return (
    <Form className='coupon-form' onSubmit={formik.handleSubmit}>
      {errorMsg &&
        <p className='text-danger fs-12px mb-1'>{errorMsg}</p>}
      {!isMobileDevice ?
        <div className={`d-flex align-items-start flex-nowrap`}>
          <Form.Group className="form-group required flex-fill">
            <Form.Control
              id="couponCode"
              type="text"
              size='sm'
              className={`rounded-0`}
              placeholder={placeholder}
              isValid={Boolean(formik.values.couponCode) && !Boolean(formik.errors.couponCode)}
              isInvalid={Boolean(formik.errors.couponCode)}
              value={formik.values.couponCode ||
                ((couponCodeQuery !== null && couponCodeQuery !== undefined && couponCodeQuery !== "") ? couponCodeQuery : "") ||
                ((mealCountDiscount !== null && mealCountDiscount !== undefined && mealCountDiscount !== "") ? mealCountDiscount : "")}
              onChange={formik.handleChange}
            />
            {formik.errors.couponCode &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.couponCode}
              </Form.Control.Feedback>}
          </Form.Group>
          {busy ?
            <Button
              variant='dark'
              className='ms-2 rounded-0'
              disabled
            >
              <Spinner animation="border" as="span" size="sm" />
              Applying ...
            </Button>
            :
            <Button
              variant='dark'
              className={`flex-fill rounded-0 coupon-form-btn`}
              type="submit"
            >
              Apply
            </Button>
          }
        </div> :
        <div className={`d-flex align-items-start flex-nowrap position-relative`}>
          <Form.Group className="form-group required flex-fill">
            <Form.Control
              id="couponCode"
              type="text"
              size='sm'
              className={`rounded-0 isMobileInputForm`}
              placeholder={placeholder}
              isValid={Boolean(formik.values.couponCode) && !Boolean(formik.errors.couponCode)}
              isInvalid={Boolean(formik.errors.couponCode)}
              value={formik.values.couponCode || ((couponCodeQuery !== null && couponCodeQuery !== undefined && couponCodeQuery !== "") ? couponCodeQuery : "")}
              onChange={formik.handleChange}
            />
            {busy ?
              <Button
                variant='dark'
                className='ms-2 rounded-0'
                disabled
              >
                <Spinner animation="border" as="span" size="sm" />
                Applying ...
              </Button>
              :
              <Button
                variant='dark'
                className={`flex-fill rounded-0 ${formik.errors.couponCode ? 'isMobileRequiredFormbtn' : 'ismobileformbtn'}`}
                type="submit"
              >
                Apply
              </Button>
            }
            {formik.errors.couponCode &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.couponCode}
              </Form.Control.Feedback>}
          </Form.Group>
        </div>}
    </Form>
  )
}