import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface AutoShipState {
  autoShipData: Record<string, any>;
  notifications: Record<string, any>;
  previousDate: Date | null;
  selectedDate: Date | null;
  selectedDates: Date[] | null;
  calendarIndex: string;
  selectedZipCode: string;
  existingCartItems: Record<string, any>;
  editCouponLines: Record<string, any>;
  turnOnAutoship: boolean;
  isOrderChanged: boolean;
  isBulkOrder: boolean;
}

const initialState: AutoShipState = {
  autoShipData: {},
  notifications: {},
  previousDate: null,
  selectedDate: null,
  selectedDates: null,
  selectedZipCode: '',
  calendarIndex: '',
  existingCartItems: {},
  editCouponLines: {},
  turnOnAutoship: false,
  isOrderChanged: false,
  isBulkOrder: false,
}

export const autoShipSlice = createSlice({
  name: "autoship",
  initialState: initialState,
  reducers: {
    setAutoShipData: (state, action) => {
      return {
        ...state,
        autoShipData: action.payload
      }
    },
    setNotifications: (state, action) => {
      return {
        ...state,
        notifications: action.payload
      }
    },
    setPreviousDate: (state, action) => {
      return {
        ...state,
        previousDate: action.payload
      }
    },
    setSelectedDate: (state, action) => {
      return {
        ...state,
        selectedDate: action.payload
      }
    },
    setSelectedDates: (state, action) => {
      return {
        ...state,
        selectedDates: action.payload
      }
    },
    setSelectedZipCode: (state, action) => {
      return {
        ...state,
        selectedZipCode: action.payload
      }
    },
    setCalendarIndex: (state, action) => {
      return {
        ...state,
        calendarIndex: action.payload
      }
    },
    setExistingCartItems: (state, action) => {
      return {
        ...state,
        existingCartItems: action.payload
      }
    },
    setEditCouponLines: (state, action) => {
      return {
        ...state,
        editCouponLines: action.payload
      }
    },
    setTurnOnAutoship: (state, action) => {
      return {
        ...state,
        turnOnAutoship: action.payload
      }
    },
    setIsOrderChanged: (state, action) => {
      return {
        ...state,
        isOrderChanged: action.payload
      }
    },
    setIsBulkOrder: (state, action) => {
      return {
        ...state,
        isBulkOrder: action.payload
      }
    },
  }
});

export const { setAutoShipData,
  setNotifications,
  setPreviousDate,
  setSelectedDate,
  setSelectedDates,
  setCalendarIndex,
  setSelectedZipCode,
  setExistingCartItems,
  setEditCouponLines,
  setTurnOnAutoship,
  setIsOrderChanged,
  setIsBulkOrder,
} = autoShipSlice.actions;
export const selectAutoShipData = (state: RootState) => state.autoship.autoShipData;
export const selectNotifications = (state: RootState) => state.autoship.notifications;
export const selectPreviousDate = (state: RootState) => state.autoship.previousDate;
export const selectSelectedDate = (state: RootState) => state.autoship.selectedDate;
export const selectSelectedDates = (state: RootState) => state.autoship.selectedDates;
export const selectCalendarIndex = (state: RootState) => state.autoship.calendarIndex;
export const selectSelectedZipCode = (state: RootState) => state.autoship.selectedZipCode;
export const selectExistingCartItems = (state: RootState) => state.autoship.existingCartItems;
export const selectEditCouponLines = (state: RootState) => state.autoship.editCouponLines;
export const selectTurnOnAutoship = (state: RootState) => state.autoship.turnOnAutoship;
export const selectIsOrderChanged = (state: RootState) => state.autoship.isOrderChanged;
export const selectIsBulkOrder = (state: RootState) => state.autoship.isBulkOrder;
export default autoShipSlice.reducer;